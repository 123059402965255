import { Component, Input } from '@angular/core';

export interface ICoursePublishDate {
  publishDate?: string;
}

@Component({
  selector: 'course-publish-date',
  template: ` <span class="twc-badge twc-badge-outline-secondary" *ngIf="item.publishDate">
    <span class="fa-regular fa-calendar-check" aria-hidden="true"></span>
    Publish date <date-time [date]="item.publishDate"></date-time>
  </span>`,
})
export class CoursePublishDateComponent {
  @Input() item!: ICoursePublishDate;
}
