import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';

enum CustomAttributeEndPoints {
  VALIDATE = '/a/custom_attributes/validation/validate/:instance/attributes/:typeId/',
  VALIDATE_INSTANCE = '/a/custom_attributes/validation/validate/:instanceType/:instanceId/attributes/:typeId/',
  GET_RESULT = '/a/custom_attributes/validation/status/:sessionId/',
}

export interface IValidationResult {
  status: string;
  message?: string;
  handler?: 'Unique';
}

@Injectable()
export class CustomAttributeService {
  constructor(
    protected http: HttpClient,
    @Inject('Instance') private readonly instance: string,
  ) {}

  public validateAttributeValue(
    instanceId: number | string | null,
    typeId: number,
    value: string | number | boolean,
  ): Observable<string> {
    return this.http
      .post<{ session_id: string }>(
        ElmsUtils.formatUrl(
          instanceId ? CustomAttributeEndPoints.VALIDATE_INSTANCE : CustomAttributeEndPoints.VALIDATE,
          {
            instanceType: this.instance,
            instanceId,
            typeId,
          },
        ),
        { value },
        {
          context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, true),
        },
      )
      .pipe(map((response) => response.session_id));
  }

  public getValidationResult(sessionId: string): Observable<IValidationResult> {
    return this.http.get<IValidationResult>(
      ElmsUtils.formatUrl(CustomAttributeEndPoints.GET_RESULT, {
        sessionId,
      }),
    );
  }
}
