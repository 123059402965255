import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { IAttributeCategory } from 'modules/custom-attributes/models/attribute-category.model';
import { Observable } from 'rxjs';

enum CustomCourseAttributeEndPoints {
  GET = '/a/custom_attributes/courses/categories/list/',
}

@Injectable()
export class CustomCourseAttributeService {
  constructor(protected http: HttpClient) {}

  getCategories(categoryId: number[], cached = true): Observable<IAttributeCategory[]> {
    const params = new HttpParams().appendAll({ categoryId });

    return this.http.get<IAttributeCategory[]>(CustomCourseAttributeEndPoints.GET, {
      params,
      context: cached && new HttpContext().set(CACHE_NAME_TOKEN, 'groupStates').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }
}
