import { NgModule } from '@angular/core';
import { CustomCourseAttributeService } from './services/custom-course-attribute.service';
import { AttributesCategoryViewComponent } from './components/attributes-category-view.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { FormsModule } from '@angular/forms';
import { AttributesCategoriesListViewComponent } from './components/attributes-categories-list-view.component';
import { CommonModule } from '@angular/common';
import { AttributesListViewComponent } from './components/attributes-list-view.component';
import { AttributeViewComponent } from './components/attribute-view.component';
import { AttributeTextComponent } from './components/attribute-text.component';
import { AttributeDateComponent } from './components/attribute-date.component';
import { AttributeBooleanComponent } from './components/attribute-boolean.component';
import { AttributeLookupComponent } from './components/attribute-lookup.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomAttributeService } from 'modules/custom-attributes/services/custom-attributes.service';
import { CustomAttributeValidatorDirective } from 'modules/custom-attributes/validators/custom-attribute.validator';

@NgModule({
  declarations: [
    AttributesCategoryViewComponent,
    AttributesCategoriesListViewComponent,
    AttributesListViewComponent,
    AttributeViewComponent,
    AttributeTextComponent,
    AttributeDateComponent,
    AttributeBooleanComponent,
    AttributeLookupComponent,
    CustomAttributeValidatorDirective,
  ],
  exports: [AttributesCategoriesListViewComponent, AttributesCategoryViewComponent, CustomAttributeValidatorDirective],
  providers: [CustomCourseAttributeService, CustomAttributeService],
  imports: [LmsComponentsModule, FormsModule, CommonModule, NgxMaskModule],
})
export class CustomAttributesModule {}
