import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ICompetency, ICompetencyModel } from 'modules/competency/models/competency.model';
import { Observable } from 'rxjs';

enum CompetencyServiceEndpoints {
  STRUCTURE = '/a/competency/train/models_structure/',
  MODELS = '/a/competency/train/models/',
}

@Injectable()
export class CompetencyService {
  constructor(private http: HttpClient) {}

  getStructure(query?: string, cached = true): Observable<ICompetency[]> {
    let params = new HttpParams();

    if (query) {
      params = params.set('query', query);
    }

    return this.http.get<ICompetency[]>(CompetencyServiceEndpoints.STRUCTURE, {
      params,
      context: cached && new HttpContext().set(CACHE_NAME_TOKEN, 'competencies').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  getModels(query?: string, cached = true): Observable<ICompetencyModel[]> {
    let params = new HttpParams();

    if (query) {
      params = params.set('query', query);
    }

    return this.http.get<ICompetencyModel[]>(CompetencyServiceEndpoints.MODELS, {
      params,
      context: cached && new HttpContext().set(CACHE_NAME_TOKEN, 'competencies').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }
}
