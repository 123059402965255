import { Component, Input, OnInit } from '@angular/core';
import { EnvironmentService } from 'core/services';
import { ICourseDetails } from 'modules/course/common/models/course.model';

@Component({
  selector: 'course-ce-dates',
  template: `
    <div class="tw-flex tw-w-full tw-gap-2">
      <span class="twc-badge twc-badge-outline-secondary" *ngIf="course.continuingEducation?.ceStartDate">
        <span class="fa fa-calendar-check"></span> CE Start Date
        <date-time [date]="course.continuingEducation.ceStartDate" [timeZone]="serverTimeZone"></date-time>
      </span>
      <span class="twc-badge twc-badge-outline-secondary" *ngIf="course.continuingEducation?.ceEndDate">
        <span class="fa fa-calendar-times"></span> CE End Date
        <date-time [date]="course.continuingEducation.ceEndDate" [timeZone]="serverTimeZone"></date-time>
      </span>
    </div>
  `,
})
export class CourseCeDatesLabelComponent implements OnInit {
  @Input() course: ICourseDetails;

  serverTimeZone: any;

  constructor(private envService: EnvironmentService) {}

  ngOnInit(): void {
    this.serverTimeZone = this.envService.serverTimeZone;
  }
}
